import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, message, Button,Popconfirm } from "antd";
import { EXTERNAL_API_PATHS } from "../utils/constants";

const useTableInmueble = ({ key }) => {
  const [formEdit] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [nameItem, setNameItem] = useState("");
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [proyectos, setProyectos] = useState([]);
  const [onEdit, setOnEdit] = React.useState(false);

  // Fetch data for the table
  useEffect(() => {
    setLoading(true);
    axios
      .get(EXTERNAL_API_PATHS[key])
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          id: item.id,
          numeroMatricula: item.matricula,
          numeroPredial: item.numero_predial,
          numeroCatastral: item.numero_catastral,
          linderos: item.linderos,
          area: item.area,
          direccion: item.direccion,
          avaluo: item.avaluo,
          coeficiente: item.coeficiente_ph,    
          proyectoId: item.proyecto?.codigo,  
          proyectoName: item.proyecto?.proyecto    
        }));
        setData(formattedData);
      })
      .finally(() => setLoading(false));
  }, [key]);

  // Fetch related projects
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["proyecto2"]).then((res) => {
      setProyectos(res.data);
    });
  }, []);



  // Create new item
  const createItem = (values) => {
    const formData = new FormData();
    formData.append("matricula", values.numeroMatricula);
    formData.append("numero_predial", values.numeroPredial);
    formData.append("numero_catastral", values.numeroCatastral);
    formData.append("linderos", values.linderos);
    formData.append("area", values.area);
    formData.append("direccion", values.direccion);
    formData.append("avaluo", values.avaluo);
    formData.append("coeficiente_ph", values.coeficiente);
    formData.append("id_proyecto", values.proyectoId);

    message.loading("Guardando...");
    axios
      .post(EXTERNAL_API_PATHS[key], formData)
      .then((res) => {
        setData((prevData) => [
          ...prevData,
          {
            id: res.data.id,
            numeroMatricula: res.data.matricula,
            numeroPredial: res.data.numero_predial,
            numeroCatastral: res.data.numero_catastral,
            linderos: res.data.linderos,
            area: res.data.area,
            direccion: res.data.direccion,
            avaluo: res.data.avaluo,
            coeficiente: res.data.coeficiente_ph,    
            proyecto: res.data.id_proyecto    
          },
        ]);
        message.success(`${nameItem} creado con éxito`);
      })
      .catch(() => {
        message.error(`No se pudo crear ${nameItem}`);
      });
  };

    // Edit item
    const editItem = (record) => {
      formEdit.setFieldsValue({
        numero_matricula: record.numero_matricula,
        numero: record.numero,
        linderos: record.linderos,
        nombre: record.nombre,
        coeficiente: record.coeficiente,
        avaluo: record.avaluo,
      });
      setEditingKey(record.id);
    };

    const save = async (id) => {
      try {
        const row = await formEdit.validateFields();
        const index = data.findIndex((item) => item.id === id);
    
        if (index > -1) {
          const updatedItem = {
            ...data[index],
            numero_matricula: row.numero_matricula,
            numero: row.numero,
            linderos: row.linderos,
            nombre: row.nombre,
            coeficiente: row.coeficiente,
            avaluo: row.avaluo,
          };
    
          const formData = new FormData();
          formData.append("numero_matricula", row.numero_matricula);
          formData.append("numero", row.numero);
          formData.append("linderos", row.linderos);
          formData.append("nombre", row.nombre);
          formData.append("coeficiente", row.coeficiente);
          formData.append("avaluo", row.avaluo);
    
          message.loading("Guardando cambios...");
          await axios.post(`${EXTERNAL_API_PATHS[key]}/${id}?_method=PUT`, formData);
    
          setData((prevData) => {
            const newData = [...prevData];
            newData[index] = updatedItem;
            return newData;
          });
    
          setEditingKey("");
          message.success("Registro editado con éxito");
        }
      } catch (error) {
        message.error("Error al guardar los cambios");
      }
    };
    
    const cancel = () => setEditingKey("");
    
    const delItem = (id) => {
      message.loading("Eliminando...");
      axios
        .delete(`${EXTERNAL_API_PATHS[key]}/${id}`)
        .then(() => {
          setData((prevData) => prevData.filter((item) => item.id !== id));
          message.success("Registro eliminado con éxito");
        })
        .catch(() => {
          message.error("No se pudo eliminar el registro");
        });
    };
    
    const updateOnEdit = (value) => {
      setOnEdit(value);
    };
    
    const isEditing = (record) => record.id === editingKey;
    
    const updateNameItem = (value) => setNameItem(value);


  return {
    formEdit,
    formCreate,
    data,
    loading,
    proyectos,
    createItem,
    editItem,
    updateOnEdit,
    delItem,
    save,
    isEditing,
    cancel,
    updateNameItem
  };
};

export default useTableInmueble;