import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
// @material-ui iconsStyle
import AttachFileIcon from "@material-ui/icons/AttachFile";
// @material-ui/core components
import { message } from "antd";

// import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// core component
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { validations } from "validators/messages";

//Styles
import { primaryColor, infoColor } from "assets/jss/material-dashboard-pro-react.js";
import useStyles from "../../assets/jss/material-dashboard-pro-react/views/common";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const themeInputInfo = createTheme({
  palette: {
      primary: {
          main: infoColor[0],
          light: infoColor[0],
          dark: infoColor[0],
      },
      secondary: {
          main: primaryColor[0],
          light: primaryColor[0],
          dark: primaryColor[0],
      }
  },
});

const FormItem = (props) => {
  const { title, createItem, loading, proyectos } = props;
  const [values, setValues] = useState({
    numeroMatricula: "",
    numeroPredial: "",
    numeroCatastral: "",
    linderos: "",
    area: "",
    direccion: "",
    coeficiente: null,
    avaluo:""
  });
  const [valuesState, setValuesState] = useState();
  const [errors, setErrors] = useState({
    numeroMatricula: null,
    numeroPredial: null,
    numeroCatastral: null,
    linderos: null,
    area: null,
    direccion: null,
    coeficiente: null,
    avaluo: null
  });

  let fileRef = useRef();
  let ProyectoRef = useRef(null);

  const classes = useStyles();

  const validateInput = (input, name) => {
    if (input.length > 0) {
      setErrors((state) => ({ ...state, [name]: null }));
    } else {
      setErrors((state) => ({ ...state, [name]: validations.BLANK }));
    }
  };

  const updateInput = (e) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setValues((state) => ({ ...state, [inputName]: inputValue }));
    validateInput(inputValue, inputName);
  };

  const getProyecto = (id) => {
    return proyectos.find((dep) => {
      return dep.codigo === id;
    });
  };
  const updateValues = (e) => {
    const { id, name, value: val } = e.target;
    if (name) {
      setValues((state) => ({
        ...state,
        [name]: getProyecto(val).codigo,
      }));
    } else {
      setValues((state) => ({ ...state, [id]: val }));
    }
  };
  
  const updateValuesState = (input, error) => {
    setValuesState((state) => ({ ...state, [input]: error }));
  };
  const add = (e) => {
    e.preventDefault();
    validateInput(values.numeroMatricula, "numeroMatricula");
    validateInput(values.numeroPredial, "numero");
    validateInput(values.numeroCatastral, "numero");
    validateInput(values.linderos, "linderos");
    validateInput(values.area, "area");
    validateInput(values.direccion, "direccion");
    validateInput(values.coeficiente, "coeficiente");
    validateInput(values.avaluo, "avaluo");
    if (
      Object.values(values).every((value) => value !== null && value !== "")
    ) {
      createItem(values, setValues);
    } else {
      message.error("Revise la informacion requerida", 1);
      return;
    }
  };

  return (
    <Card>
      <CardHeader color="primary" text>
        <CardText className={classes.cardText} color="primary">
          <h4 className={classes.colorWhite}>{title}</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <ThemeProvider theme={themeInputInfo}>
          <form onSubmit={add}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              {/* Campo 1: Número Matrícula Inmobiliaria (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="numeroMatricula"
                    type="text"
                    placeholder="NÚMERO MATRÍCULA INMOBILIARIA *"
                    value={values.numeroMatricula}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroMatricula}
                    helperText={errors.numeroMatricula}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              
              {/* Campo 2: Número Predial (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="numeroPredial"
                    type="text"
                    placeholder="NÚMERO PREDIAL *"
                    value={values.numeroPredial}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroPredial}
                    helperText={errors.numeroPredial}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 4: Número Catastral (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="numeroCatastral"
                    type="text"
                    placeholder="NÚMERO CATASTRAL *"
                    value={values.numeroCatastral}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroCatastral}
                    helperText={errors.numeroCatastral}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 5: Linderos (text) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="linderos"
                    type="text"
                    placeholder="LINDEROS *"
                    value={values.linderos}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.linderos}
                    helperText={errors.linderos}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 6: Area (text) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="area"
                    type="text"
                    placeholder="AREA *"
                    value={values.area}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.area}
                    helperText={errors.area}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              
               {/* Campo 7: direccion (text) */}
               <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="direccion"
                    type="text"
                    placeholder="DIRECCION *"
                    value={values.direccion}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.direccion}
                    helperText={errors.direccion}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 8: Coeficiente (float) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="coeficiente"
                    type="number"
                    step="0.01"
                    placeholder="COEFICIENTE *"
                    value={values.coeficiente}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.coeficiente}
                    helperText={errors.coeficiente}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 9: Avalúo Catastral Prorrata (number with increment/decrement) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="avaluo"
                    type="number"
                    step="1"
                    placeholder="AVALÚO CATASTRAL PRORRATA *"
                    value={values.avaluo}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.avaluo}
                    helperText={errors.avaluo}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 10: Proyecto (Select) */}
              <GridItem xs={12} sm={6} md={4} lg={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      label="Proyecto"
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      displayEmpty
                      defaultValue=""
                      onChange={(event) => {
                        if (event.target.value !== "") {
                          updateValuesState("proyectoId", "success");
                        } else {
                          updateValuesState("proyectoId", "error");
                        }
                        updateValues(event);
                      }}
                      inputProps={{
                        name: "proyectoId",
                        inputRef: ProyectoRef,
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Seleccione Proyecto
                      </MenuItem>
                      {proyectos?.map(({ codigo, proyecto }) => (
                        <MenuItem
                          key={codigo}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={codigo}
                        >
                          {proyecto}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </GridItem>

              {/* Boton Añadir */}
              <Grid item sm={3} md={2}>
                <Button
                  type="submit"
                  disabled={loading}
                  color="rose"
                  size="sm"
                  fullWidth
                >
                  Añadir
                </Button>
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>
      </CardBody>
    </Card>
  );
  
  
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createItem: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FormItem;
