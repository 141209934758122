import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Switch, Form } from "antd";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "../../assets/jss/material-dashboard-pro-react/views/common";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  children,
  inputType,
  form,
  proyectos,
  ...restProps
}) => {

  const [state, setState] = useState("Seleccione...");
  const classes = useStyles();

  const handleFile = (e) => {
    setState(e.target.files[0].name);
    form.setFieldsValue({
      plantilla: e.target.files[0],
    });
  };

  const getProyecto = (id) => {
    return proyectos.find((dep) => {
      return dep.codigo === id;
    });
  };

  const updateValues = (e) => {
    const { id, name, value: val } = e.target;
      form.setFieldsValue({
        nombre_proyecto: getProyecto(val).codigo
      })
  };
  
  const updateValuesState = (input, error) => {
    form.setFieldsValue({
      nombre_proyecto: error
    });
  };

  let inputNode = null;
  if (editing) {
    switch (inputType) {
      case "file":
        inputNode = (
          <>
            <Input
              value={state}
              className={classes.label}
              addonAfter={
                <label htmlFor="plantilla">
                  <IconButton
                    color="primary"
                    aria-label="Subir archivo"
                    component="span"
                  >
                    <AttachFileIcon fontSize="inherit" />
                  </IconButton>
                </label>
              }
            />
            <input
              id="plantilla"
              name="plantilla"
              type="file"
              accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFile}
              hidden
            />
          </>
        );
        break;
      case "select":
          inputNode = (
            <>
              <Select
                      label="Proyecto"
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      displayEmpty
                      defaultValue=""
                      onChange={(event) => {
                        if (event.target.value !== "") {
                          updateValuesState("nombre_proyecto", "success");
                        } else {
                          updateValuesState("nombre_proyecto", "error");
                        }
                        updateValues(event);
                      }}
                      inputProps={{
                        name: "nombre_proyecto"
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Seleccione Proyecto
                      </MenuItem>
                      {proyectos?.map(({ codigo, proyecto }) => (
                        <MenuItem
                          key={codigo}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={codigo}
                        >
                          {proyecto}
                        </MenuItem>
                      ))}
                    </Select>
            </>
          );
          break;
      default:
        inputNode = <Input />;
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Agregue ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: "",
  title: "",
  children: null,
  inputType: "",
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  inputType: PropTypes.string,
};

export default EditableCell;
