import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, message, Button,Popconfirm } from "antd";
import { EXTERNAL_API_PATHS } from "../utils/constants";

const useTablePlantilla = ({ key }) => {
  const [formEdit] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [nameItem, setNameItem] = useState("");
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [proyectos, setProyectos] = useState([]);
  const [onEdit, setOnEdit] = React.useState(false);

  // Fetch data for the table
  useEffect(() => {
    setLoading(true);
    axios
      .get(EXTERNAL_API_PATHS[key])
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          key:  item.id,
          id: item.id,
          nombre_plantilla: item.nombre_plantilla,
          nombre_proyecto: item.proyecto2?.proyecto,
          plantilla: item.url_documento,
        }));
        setData(formattedData);
      })
      .finally(() => setLoading(false));
  }, [key]);

  // Fetch related projects
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["proyecto2"]).then((res) => {
      setProyectos(res.data);
    });
  }, []);

  // Download document functionality
  const getDocument = async (record) => {
    try {
      const response = await axios.get(`${EXTERNAL_API_PATHS.files}/${record.urlDocumento}`, {
        responseType: "blob",
      });
      console.log(record)
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = record.fileName || "documento";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        message.error("Error en la descarga del archivo.");
      }
    } catch (error) {
      message.error("Ocurrió un error al descargar el archivo.");
    }
  };

  // Create new item
  const createItem = (values) => {
    const formData = new FormData();
    formData.append("archivo", values.file);
    formData.append("nombre_plantilla", values.name);
    formData.append("variables", JSON.stringify({ test: "test" }));
    formData.append("proyecto", values.proyecto);

    message.loading("Guardando...");
    axios
      .post(EXTERNAL_API_PATHS[key], formData)
      .then((res) => {
        setData((prevData) => [
          ...prevData,
          {
            id: res.data.id,
            nombre_plantilla: values.name,
            nombre_proyecto: proyectos.find((p) => p.id === values.proyecto)?.proyecto,
            plantilla: res.data.url_documento,
          },
        ]);
        message.success(`${nameItem} creado con éxito`);
      })
      .catch(() => {
        message.error(`No se pudo crear ${nameItem}`);
      });
  };

  // Edit item
  const editItem = (record) => {
    formEdit.setFieldsValue({
      nombre_plantilla: record.nombre_plantilla,
      proyecto: proyectos.find((p) => p.proyecto === record.nombre_proyecto)?.id,
    });
    setEditingKey(record.id);
  };

  const save = async (id) => {
    try {
      const row = await formEdit.validateFields();
      const index = data.findIndex((item) => item.id === id);

      console.log(row)

      if (index > -1) {
        const updatedItem = {
          ...data[index],
          nombre_plantilla: row.nombre_plantilla,
          nombre_proyecto: proyectos.find((p) => p.codigo === row.nombre_proyecto)?.proyecto,
        };

        const formData = new FormData();
        formData.append("nombre_plantilla", row.nombre_plantilla);
        formData.append("proyecto", row.nombre_proyecto); //pendiente debe ser el id
        formData.append("variables", JSON.stringify({ test: "test" })); // siempre poner esto por ahora, que estoy haciendo unas pruebas
        formData.append("archivo", row.plantilla); //pendiente como me lo envias?  si esto cambia

        if (row.file) {
          formData.append("archivo", row.file);
        }

        message.loading("Guardando cambios...");
        await axios.post(`${EXTERNAL_API_PATHS[key]}/${id}?_method=PUT`, formData);

        setData((prevData) => {
          const newData = [...prevData];
          newData[index] = updatedItem;
          return newData;
        });

        setEditingKey("");
        message.success("Registro editado con éxito");
      }
    } catch (error) {
      message.error("Error al guardar los cambios");
    }
  };

  
  const cancel = () => setEditingKey("");

  const delItem = (id) => {
    message.loading("Eliminando...");
    axios
      .delete(`${EXTERNAL_API_PATHS[key]}/${id}`)
      .then(() => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        message.success(`${nameItem} eliminado con éxito`);
      })
      .catch(() => {
        message.error(`No se pudo eliminar ${nameItem}`);
      });
  };


  const updateOnEdit = (value) => {
    setOnEdit(value);
  };
  
  const isEditing = (record) => record.id === editingKey;

  const updateNameItem = (value) => setNameItem(value);


  return {
    formEdit,
    formCreate,
    data,
    loading,
    proyectos,
    createItem,
    editItem,
    updateOnEdit,
    delItem,
    save,
    getDocument,
    isEditing,
    cancel,
    updateNameItem
  };
};

export default useTablePlantilla;